import Base = require("Everlaw/Base");
import Bugsnag = require("Everlaw/Bugsnag");
import Dom = require("Everlaw/Dom");
import * as DateTimePrecision from "Everlaw/DateTimePrecision";
import { OrganizationId } from "Everlaw/MinimalOrganization";
import { assertNever } from "Everlaw/Util";

class DatabaseField extends Base.Object {
    static EVERLAW_ACCESS_RESTRICTIONS = "Everlaw access restrictions";
    static EVERLAW_ACCESS_RESTRICTIONS_CJIS_VALUE = "CJIS";
    static EVERLAW_ACCESS_RESTRICTIONS_INFO =
        "Informs Everlaw users that this database contains data with special access requirements";
    get className() {
        return "DatabaseField";
    }
    // Used by the single select field in frontend to denote it doesn't have a value selected.
    static NO_VALUE = -1;
    override id: DatabaseField.Id;
    name: string;
    organizationId: OrganizationId;
    visibility: DatabaseField.Visibility;
    type: DatabaseField.Type;
    // Contains information like options for single or multi select, or precision for datetime.
    json: DatabaseField.FieldJson;
    isRequired: boolean;
    isPinned: boolean;
    // See DatabaseField.java for an explanation of how rank is used for sorting.
    rank: string;
    constructor(params: DatabaseField.Params) {
        super(params);
        this._mixin(params);
    }
    // Human-readable display of this field's type
    typeDisplay() {
        return DatabaseField.fieldTypeDisplay(this.type);
    }
    static fieldTypeDisplay(type: DatabaseField.Type): string {
        switch (type) {
            case DatabaseField.Type.DATE:
                return "Date";
            case DatabaseField.Type.MULTI_SELECT:
                return "Multi-select dropdown";
            case DatabaseField.Type.NUMBER:
                return "Number";
            case DatabaseField.Type.SINGLE_SELECT:
                return "Single-select dropdown";
            case DatabaseField.Type.TEXT:
                return "Text field";
            default:
                assertNever(type);
        }
    }
    // Human-readable display of this field's visibility
    visibilityDisplay(): Dom.Content {
        if (!(this.visibility in DatabaseField.VISIBILITY_DESCRIPTION)) {
            Bugsnag.notify(
                Error(`Unknown database field visibility for field ${this.id}: ${this.visibility}`),
            );
            return Dom.div("Unknown visibility.");
        }
        return Dom.div(
            Dom.div(DatabaseField.VISIBILITY_DESCRIPTION[this.visibility].title),
            Dom.div(
                { class: "caption-text" },
                DatabaseField.VISIBILITY_DESCRIPTION[this.visibility].caption,
            ),
        );
    }
    isSingleOrMultiSelect() {
        return DatabaseField.typeIsSingleOrMultiSelect(this.type);
    }
    static typeIsSingleOrMultiSelect(type: DatabaseField.Type): boolean {
        return (
            type === DatabaseField.Type.SINGLE_SELECT || type === DatabaseField.Type.MULTI_SELECT
        );
    }
    isEverlawAccessRestriction() {
        return this.name === DatabaseField.EVERLAW_ACCESS_RESTRICTIONS;
    }
    override _mixin(params: any) {
        Object.assign(this, params);
    }
    override display() {
        return this.name;
    }
    static optionsSavable(oldVersion: DatabaseField, newVersion: DatabaseField): boolean {
        if (!oldVersion && !newVersion) {
            // Both undefined
            return true;
        }
        if (!oldVersion || !newVersion) {
            // Only one is undefined
            return false;
        }
        if (!newVersion.isSingleOrMultiSelect() || !newVersion.json) {
            // New version is invalid, or this check does not apply
            return false;
        }
        const startingOptions = (oldVersion.json as DatabaseField.SelectJson).options;
        const newOptions = (newVersion.json as DatabaseField.SelectJson).options;
        if (startingOptions.length !== newOptions.length) {
            return true;
        }
        for (let i = 0; i < startingOptions.length; i++) {
            if (startingOptions[i].id !== newOptions[i].id) {
                return true;
            }
            if (startingOptions[i].text !== newOptions[i].text) {
                return true;
            }
        }
        return false;
    }
    static datePrecisionSavable(oldVersion: DatabaseField, newVersion: DatabaseField): boolean {
        if (!oldVersion && !newVersion) {
            // Both undefined
            return true;
        }
        if (!oldVersion || !newVersion) {
            // Only one is undefined
            return false;
        }
        if (newVersion.type !== DatabaseField.Type.DATE) {
            // This check does not apply
            return false;
        }
        return (
            (oldVersion.json as DatabaseField.DateTimeJson).precision
            !== (newVersion.json as DatabaseField.DateTimeJson).precision
        );
    }

    static hasSavableChanges(oldVersion?: DatabaseField, newVersion?: DatabaseField): boolean {
        if (!oldVersion && !newVersion) {
            // Both undefined
            return true;
        }
        if (!oldVersion || !newVersion) {
            // Only one is undefined
            return false;
        }
        return (
            newVersion.name !== oldVersion.name
            || newVersion.visibility !== oldVersion.visibility
            || newVersion.isRequired !== oldVersion.isRequired
            || newVersion.type !== oldVersion.type
            || DatabaseField.optionsSavable(oldVersion, newVersion)
            || DatabaseField.datePrecisionSavable(oldVersion, newVersion)
        );
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module DatabaseField {
    export type Id = number & Base.Id<"DatabaseField">;

    export enum Visibility {
        ALL_USERS = "ALL_USERS",
        ORG_AND_LEGAL_HOLD_ORG_AND_DB_ADMINS = "ORG_AND_LEGAL_HOLD_ORG_AND_DB_ADMINS",
        ORG_AND_LEGAL_HOLD_ORG_ADMINS = "ORG_AND_LEGAL_HOLD_ORG_ADMINS",
    }

    export const VISIBILITY_DESCRIPTION: {
        [key in DatabaseField.Visibility]: { title: string; caption: string };
    } = {
        [DatabaseField.Visibility.ALL_USERS]: {
            title: "All users",
            caption:
                "Legal holds organization admins, database admins, and project users will "
                + "only see this information if the field is pinned",
        },
        [DatabaseField.Visibility.ORG_AND_LEGAL_HOLD_ORG_AND_DB_ADMINS]: {
            title: "Organization, legal holds organization, and database admins",
            caption:
                "Legal holds organization admins and database admins will only see this "
                + "information if the field is pinned",
        },
        [DatabaseField.Visibility.ORG_AND_LEGAL_HOLD_ORG_ADMINS]: {
            title: "Organization and legal holds organization admins only",
            caption:
                "Legal holds organization admins will only see this information if the field is pinned",
        },
    };

    export enum Type {
        TEXT = "TEXT",
        SINGLE_SELECT = "SINGLE_SELECT",
        MULTI_SELECT = "MULTI_SELECT",
        NUMBER = "NUMBER",
        DATE = "DATE",
    }

    export type Option = {
        id: number;
        text: string;
    };

    export type SelectJson = {
        options: Option[];
        nextOptionId: number;
    };

    export type DateTimeJson = {
        precision: number;
    };

    export type FieldJson = SelectJson | DateTimeJson;

    export const isSelectJson = (object?: FieldJson): object is SelectJson => {
        return !!object && "options" in object;
    };

    export const isDateTimeJson = (object?: FieldJson): object is DateTimeJson => {
        return !!object && "precision" in object;
    };

    export const getDefaultJson = (type: DatabaseField.Type): FieldJson | undefined => {
        if (DatabaseField.typeIsSingleOrMultiSelect(type)) {
            return {
                options: [],
                nextOptionId: 1,
            };
        }
        if (type === DatabaseField.Type.DATE) {
            return {
                precision: DateTimePrecision.Precision.dateOnly,
            };
        }
        return undefined;
    };

    export const MAX_PINNED_FIELDS = 3;

    export interface Params {
        id: DatabaseField.Id;
        name: string;
        organizationId: OrganizationId;
        visibility: DatabaseField.Visibility;
        type: DatabaseField.Type;
        isRequired: boolean;
        isPinned: boolean;
        rank: string;
        json?: DatabaseField.FieldJson;
    }
}

export = DatabaseField;
