import Base = require("Everlaw/Base");
import { Color, AccessibilityColorPicker } from "Everlaw/ColorUtil";

class Language extends Base.Object {
    get className() {
        return "Language";
    }
    // the two-letter ISO code of the Language (not a number)
    override id: string;
    displayName: string;
    color: Color;
    private static COLOR_PICKER = new AccessibilityColorPicker();
    constructor(params: any) {
        super(params);
        this._mixin(params);
        this.color = Language.COLOR_PICKER.getNext().setA(0.5);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
    }
    override display() {
        return this.displayName;
    }
    getColor() {
        return this.color;
    }
}

if (JSP_PARAMS.Language) {
    Base.set(Language, JSP_PARAMS.Language.json);
}

export { Language };
